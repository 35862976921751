import classNames from 'classnames';
import SEO from 'components/seo/seo';
import TeaserGrid from 'components/teaser/teaserGrid';
import { graphql } from 'gatsby';
import React, { useRef, useState } from 'react';
import { pageContextProps } from 'utils/props.utils';
import { MediaTeaserProps } from 'components/mediathekTeaser/mediathekTeaser';

interface Props {
  pageContext: pageContextProps;
  data: {
    mediathek: {
      edges: {
        node: MediaTeaserProps;
      }[];
    };
  };
}

const MediathekTemplate: React.FC<Props> = (props) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [preventDrag, setPreventDrag] = useState<boolean>(false);

  return (
    <div
      className={classNames('template-publications', 'publications-template', {
        'is-loading-complete': isLoaded,
        'is-prevent-drag': preventDrag,
      })}
      id={props.pageContext.id}
      ref={mainRef}
    >
      <SEO {...props.pageContext.seo} />
      <section className="section">
        <div className="section-header">
          <h5 className="section-header__title">{props.pageContext.title}</h5>
        </div>
        <TeaserGrid mediathek={props.data.mediathek.edges} />
      </section>
    </div>
  );
};

export default MediathekTemplate;

export const query = graphql`
  query ($id: String!, $language: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      title
    }
    mediathek: allWpMediathek(sort: { order: DESC, fields: date }) {
      edges {
        node {
          id
          title
          AcfMediathek {
            videoEmbedUrl
            podigeeEmbedUrl
            text
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
